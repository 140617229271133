export default class AuthNetworkOperation {

  constructor(repositoryInstance, user) {
    this.repositoryInstance = repositoryInstance;
    this.userInstance = user;
  }

  async login(userName, password) {
    try {
      const { data } = await this.repositoryInstance.login(userName, password);
      if (data.error) {
        return data
      } else {
        const parseUserData = JSON.parse(data?.userData)
        const userUpdatedData = {
          token: data.token,
          userData: parseUserData
        }
        return userUpdatedData
      }
    } catch (error) {
      console.error(error);
    }
  }

  async resetPassword(_id, role, schoolId, bodyData){
    try {
      return this.repositoryInstance.resetPassword(_id, role, schoolId, bodyData)
      
    } catch (error) {
      console.error(error)
    }
  }

  async saveProductEnquiry(bodyData) {
    try {
      const  {data}  = await this.repositoryInstance.saveProductEnquiry(bodyData);
      return data;
    } catch (error) {
      console.error(error);
    }
  }


}
