import './App.css';
import Landing from './component/Home/landing';

// import Landing from './component/Home/landing';
import LoginLayout from './component/loginLayout/loginLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';




function App() {

  return (
    <>
      <BrowserRouter>
        <Routes >

          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<LoginLayout />} />
        </Routes>
      </BrowserRouter>
    </>




  );
}

export default App;
